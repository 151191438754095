<template>
<!-- 体育协会 右上的数据展示 -->
	<div>
		<div class="container darkBack">
			<div class="lightBack dataItem" v-for="(item,index) in dataList" :key="index">
				<img class="img" :src="item.img" >
				<div>
					<p class="font14 gray">{{item.title}}</p>
					<p class="font24 whiteFont">
						<countTo :startVal='0' :endVal='item.num' :duration='3000'></countTo>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 引入数字滚动组件
    import countTo from 'vue-count-to';
    import {getAjax, timestamp} from "../../../assets/js/websocket";
  export default {
    components: { countTo },
		data() {
			return{
				// dataList:[
				// 	window.setData('体育协会-全市协会总量',{ title:'全市协会总量' , num:341 , img:require('../../../assets/images/associationNum.png')}),
				// 	window.setData('体育协会-会员总数量',{ title:'会员总数量' , num:341234 , img:require('../../../assets/images/vipNum.png')}),
				// ]
                dataList:[
                    { title:'全市协会总量' , num:341 , img:require('../../../assets/images/associationNum.png')},
                    { title:'会员总数量' , num:341234 , img:require('../../../assets/images/vipNum.png')},
                ],
				topDataTimer:""
			}
		},
		created() {
			var that = this
			that.getData();
		},
		methods:{
			getData(){
				var that = this
				var data = {
					token: window.getToken(),
					app_id: window.appId,
					time: timestamp(),
				}
				getAjax({
					url: "/map/screen/getSportsAssociationAll",
					method:"GET",
					data:data,
					success:function(res){
						if(res.result){
							// console.log(55555555555, res.data.list)
						that.dataList[0].num = res.data.association_total
						that.dataList[1].num = res.data.association_user_total
						}
					},
				})
			}
		},
		mounted() {
			this.topDataTimer = setInterval(() => {
				this.getData();
			}, 300000);
		},
		beforeDestroy () {
			clearInterval(this.topDataTimer)
		}
	}
</script>

<style scoped>
	.gray{color: #CCCCCC;}
	.font24{font-size: 0.24rem}
	.container{
		width: 6.01rem;
		height: 1.19rem;
		position: relative;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.dataItem{
		display: flex;
		align-items: center;
		width: 2.54rem;
		height: 0.81rem;
	}
	.img{
		width: 0.44rem;
		height: 0.39rem;
		margin: 0rem 0.2rem 0rem 0.2rem;
	}
</style>